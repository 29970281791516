@import '../../../App.scss';
.app-header__open-menu {
  cursor: pointer;
  width: 33px;
  height: 33px;
  padding-left: 20px;
  .app-header__open-menu__btn {
    width: 33px;
    height: 33px;
    content: url('../../../images/menu-hamburger-sem-clicar.svg');
  }
  .app-header__open-menu__btn:hover {
    content: url('../../../images/menu-hamburger-over.svg');
  }
  .app-header__open-menu__btn--active {
    content: url('../../../images/menu-hamburger-clicado.svg');
  }
}

.navbar {
  padding: 0px;
}

.header-profile-dropdown__nav {
  float: right;
  margin-right: 0;
  border-radius: 6px;
  width: 210px !important;
  height: 46px !important;
  display: flex;
  justify-content: center;
  #account-menu {
    display: flex;
    justify-content: center;
  }
  #institutions-menu {
    margin-left: auto;
  }
}

.header-profile-dropdown__nav--open {
  border: solid 1px $light-periwinkle;
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#header-profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 251px !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: $greyish-brown !important;
  font-family: 'Raleway' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.8px !important;
}

.profile {
  margin-right: 10px;
  content: url('../../../images/perfil.svg');
}

.subscription {
  margin-right: 10px;
  content: url('../../../images/cartao.svg');
}

.doubts {
  margin-right: 10px;
  content: url('../../../images/suporte.svg');
}

.logout {
  margin-right: 10px;
  content: url('../../../images/sair.svg');
}

.dropdown-menu {
  border: solid 1px $light-periwinkle !important;
  border-radius: 6px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07) !important;
  padding: 0px;
  margin: 0px !important;
  margin-right: -2px !important;
}

.dropdown-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-toggle::after {
  border: none;
  content: url('../../../images/collapse_aberto.svg');
}

.nav-meds {
  margin-left: 70%;
  margin-top: 15px;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 1000;
}

.dropdown-item.active {
  background-color: #ffffff !important;
  color: #f67d40;
  display: flex;
}

.search-bar-container {
  display: flex;
  width: 572px;
  border-radius: 6px;
  height: 47px;
  position: relative;
}

.search-bar {
  display: flex;
  width: 572px;
  border: solid 1px $ice-blue;
  border-radius: 6px;
  height: 47px;
  justify-content: space-between;
}

.search-results {
  width: 401px;
  position: absolute;
  z-index: 10000;
  top: 46px;
  right: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px $ice-blue;
  background-color: $white-three;
}

.search-results__description {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px $ice-blue;
  cursor: pointer;
}

.search-results__description__text {
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a4a4a4;
}

.search-results__description__text--bold {
  font-weight: 600;
}

.result-item {
  width: 100%;
  border-bottom: solid 1px $ice-blue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}

.result-item__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.result-item__right {
  display: flex;
}

.result-item__type {
  width: auto;
  height: 16px;
  border-radius: 4px;
  background-color: #fbefd5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap;
}

.result-item__type__text {
  font-family: 'Raleway';
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $greyish-brown;
  text-align: center;
}

.result-item__text {
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $greyish-brown;
}

.search-container {
  width: 401px;
  border-left: solid 1px $ice-blue;
  .MuiFilledInput-input {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .auto-complete-container .MuiFilledInput-input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 45px;
  }
  .auto-complete-container .MuiFilledInput-input:focus {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 45px;
  }
}

.select-container {
  max-height: 45px !important;
  .MuiSelect-root {
    display: flex;
    justify-content: center;
    width: 169px !important;
    height: 45px !important;
  }
  .MuiFormControl-root {
    width: 171px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 6px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 6px !important;
    border: solid 1px $ice-blue;
    display: flex;
    justify-content: center;
  }
  .MuiOutlinedInput-root {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 0px !important;
    border: solid 1px $ice-blue;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $greyish-brown !important;
  }
  .MuiInputBase-root {
    width: 171px;
    height: 45px;
    border-radius: 0px !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    border: none !important;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $greyish-brown !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .MuiInput-underline:before {
    border: none !important;
  }
  .Mui-focused {
    border: none !important;
    height: 45px !important;
  }
  .MuiInputBase-input {
    width: 100% !important;
    // height: 100%!important;
    padding-left: 16px;
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $greyish-brown;
  }
  .MuiSelect-icon {
    right: 5px !important;
    width: 0.8em;
    color: $greyish-brown;
  }
}

.main-nav-container {
  .main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 6px;
    a {
      margin-left: 20px;
      margin-right: 20px;
    }
    a:hover {
      color: #ef7d00;
      text-decoration: none !important;
    }
    .link-nav-main {
    }
    .active-nav {
    }
  }
}

.dropdown-item {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-top: solid 1px $ice-blue;
  white-space: normal !important;
  line-height: 1.5;
  height: 40px !important;
}

.logout-item:hover {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.first-dropdown-item {
  border-top: none;
}

.first-dropdown-item:hover {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
  width: 210px !important;
}

.navbar-nav .dropdown-menu {
  width: 210px !important;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.83;
  letter-spacing: normal;
  color: $greyish-brown;
}
