@import './icon-fonts.scss';

@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Bold.otf);
  font-weight: 600;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/HelveticaNeue-Light.otf);
  font-weight: 300;
}
